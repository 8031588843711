const collateralsMap = {
  // Pumps
  leavein_conditioner_light: 'Light Leave-in',
  leavein_conditioner_rich: 'Rich Leave-in',
  sticker_pump: 'Pump Sticker',
  skincare_sticker_pump_and_dropper_opt_out: 'Pump Sticker',
  pump_150_ml: '150 ml',
  sticker_pump_skincare: 'Pump sticker skincare',
  pump_cleanser: 'Cleanser pump',
  dropper_serum: 'Serum dropper',

  // Collaterals
  membership_postcard_v1: 'Hair Type 1 - 2',
  membership_postcard_v2: 'Hair Type 3 - 4',
  membership_postcard_v3: '- All Hair Types',

  // Loyalty Gifts
  loyalty_gift: 'Loyalty Gift',
  loyalty_gift_3: '3rd Subscription Order Loyalty Gift',
  loyalty_gift_3_comb_type_1_2: '3rd Subscription Order Loyalty Gift - Comb Type 1 & 2',
  loyalty_gift_3_comb_type_3_4: '3rd Subscription Order Loyalty Gift - Comb Type 3 & 4',
  loyalty_gift_3_corsica_hair_perfume: '3rd Subscription Order Loyalty Gift - Perfume Corsica',
  corsica_hair_perfume_10_ml_postcard:
    '3rd Subscription Order Loyalty Gift - Perfume Corsica Postcard',
  pillowcase: 'Pillowcase',
  towel: 'Towel',
  gwp_2021_memorial_day: 'GWP 2021 Pillowcase',
  hair_towel_gwp: 'Hair Towel GWP',
  filet_bag_gwp: 'Filet Bag GWP',
  digital_gwp: 'gwp 2022 digital',
  megan_roup: 'Megan Roup Digital GWP',

  // boxes
  box_1_product_v3: '1-product box',
  box_2_product_v3: '2-product box',
  box_3_product_v3: '3-product box',
  box_4_product_v3: '4-product box',
  box_5_product_v3: '5-product box',

  // Unit cartons
  cleanser_unit_carton: 'Cleanser Carton',
  moisturizer_unit_carton: 'Moisturizer Carton',
  serum_unit_carton: 'Serum Carton',

  // Trays and modules
  tray_supplements: 'Supplements Tray',
  tray_bottle_1_prod: '1-Product Tray (Bottle)',
  tray_specialty_1_prod: '1-Product Tray (Specialty) ',
  tray_jar_1_prod: '1-Product Tray (Jar)',
  tray_dry_shampoo_1_prod: '1-Product Tray (Dry Shampoo)',
  tray_bottle_jar: '2-Product Tray (1 Bottle + 1 Jar)',
  tray_bottle_bottle: '2-Product Tray (2 Bottles)',
  tray_jar_bottle_bottle: '3-Product Tray (2 Bottles + 1 Jar)',
  tray_2_bottles_1_specialty: '3-Product Tray (2 Bottles + 1 Specialty) ',
  tray_2_bottles_1_dry: '3-Product Tray (2 Bottles + 1 Dry Shampoo)',
  tray_2_bottles_1_specialty_1_jar: '4-Product Tray (2 Bottles + 1 Specialty + 1 Jar) ',
  tray_2_bottles_1_dry_1_jar: '4-Product Tray (2 Bottles + 1 Dry Shampoo + 1 Jar) ',
  tray_bottle_bottle_v2: 'Two Bottle Tray',
  tray_bottle_bottle_v3: 'Two Bottle Tray',
  module_bottle: 'Bottle Module',
  module_bottle_v2: 'Bottle Module',
  module_bottle_v3: 'Bottle Module',
  module_foam_pad: 'Foam Pad',
  module_jar: 'Jar Module',
  module_jar_v2: 'Jar Module',
  module_jar_v3: 'Jar Module',
  module_specialty: 'Specialty Module',
  module_specialty_v2: 'Specialty Module',
  module_specialty_v3: 'Small Bottle Module',
  module_dry_shampoo: 'Dry Shampoo Module',
  module_dry_shampoo_v2: 'Dry Shampoo Module',
  module_dry_shampoo_v3: 'Dry Shampoo Module',
  module_dry_shampoo_big: 'Dry Shampoo big Module',
  module_blank: 'Blank Module',
  module_cleanser: 'Cleanser module',
  module_moisturizer: 'Moisturizer module',
  module_serum: 'Serum module',
  module_oil: 'Oil module',
  module_oil_v3: 'Hairoil Module',
  module_supplements: 'Supplements module',
  module_supplements_v3: 'Supplement 2 Jar Module',
  module_filling_paper: 'Filling paper',
  leaflet_core: 'Leaflet - Core',
  letter_core: 'Letter - Core',
  envelope_core: 'Envelope - Core',
  envelope_oil: 'Envelope - Oils',
  letter_oil: 'Letter - Oils',
  leaflet_oil: 'Leaflet - Oils',
  envelope_brush: 'Envelope - Brush',
  envelope_supplements: 'Envelope - Supplements',
  letter_supplements: 'Letter - Supplements',
  leaflet_supplements: 'Leaflet - Supplements',
  reduced_leaflet: 'Condensed leaflet',
  condensed_leaflet_skincare: 'Condensed leaflet',
  envelope_skincare_haircare_core: 'Envelope',
};

export default collateralsMap;
