import { createApi } from '@reduxjs/toolkit/query/react';

// import { fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import fetchJson from 'utils/custom-queries/fetchJson';

// const customBaseQuery = async (args: any, api: any, extraOptions: any) => {
//   if (typeof args === 'string' || args.url) {
//     const realBaseQuery = fetchJson({ baseUrl: '/v1/backoffice' });
//     return realBaseQuery(args, api, extraOptions);
//   }
//   console.log('fake query');

//   const fakeQuery = fakeBaseQuery();
//   return fakeQuery(args, api, extraOptions);
// };

const backofficeApi = createApi({
  reducerPath: 'backofficeApi',
  baseQuery: fetchJson({ baseUrl: '/v1/backoffice' }),
  endpoints: () => ({}),
});

export default backofficeApi;
