import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

const dataDrivenLocalSlice = createSlice({
  name: 'dataDrivenLocalSlice',
  initialState: { scannedPubkeys: [] as string[] },
  reducers: {
    addScannedPubkey(draftState, action: PayloadAction<string>) {
      const { payload } = action;
      draftState.scannedPubkeys.push(payload);
    },
    clearScannedPubkeys: draftState => {
      draftState.scannedPubkeys = [];
    },
  },
});

const { reducer, actions } = dataDrivenLocalSlice;

export const { addScannedPubkey, clearScannedPubkeys } = actions;

export default reducer;
